import { BehaviorSubject, Subject } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { EnvironmentVariables } from "../../environment_variables";
import { DomainFailure, NetworkFailure, Result, useStore } from "../../core";
import { AuthRepository } from "../../../features/auth/auth";
import { handleDates } from "./injectable-modules";

export enum SocketConnectionState {
  disconnected = "disconnected",
  connecting = "connecting",
  connected = "connected",
  reconnecting = "reconnecting",
}

enum SocketCloseReason {
  maxConnectionReached = "MAX_CONNECTION_REACHED",
  invalidToken = "INVALID_TOKEN",
  deadSession = "DEAD_SESSION",
  closedManually = "CLOSED_MANUALLY",
}

class SocketClientReconnectionPolicy {
  private _retryCount: number = 0;
  private maxRetryDelayInMs = 32_000;

  get retryCount() {
    return this._retryCount;
  }

  getNextRetryDelayInMs(): number {
    const nextRetryDelay = Math.pow(2, this._retryCount) * 1000;
    this._retryCount++;
    if (nextRetryDelay <= this.maxRetryDelayInMs) {
      return nextRetryDelay;
    }
    return this.maxRetryDelayInMs;
  }

  reset() {
    this._retryCount = 0;
  }

  get canRetryManually(): boolean {
    return this._retryCount > 2;
  }
}

export class SocketClient {
  constructor(private readonly _authRepository: AuthRepository) {
    setInterval(() => {
      if (this.connectionState === SocketConnectionState.connected) {
        this.send(
          {
            action: "ping",
            payload: {},
          },
          false
        );
      }
    }, 30_000);
  }

  private _connection?: WebSocket;
  private _connectionState = new BehaviorSubject<SocketConnectionState>(
    SocketConnectionState.disconnected
  );
  private reconnectionPolicy = new SocketClientReconnectionPolicy();
  private nextReconnectionTimer?: ReturnType<typeof setTimeout>;

  public get connectionStateSubject() {
    return this._connectionState.asObservable();
  }

  public get connectionState() {
    return this._connectionState.value;
  }

  private readonly listeners = new Map<string, Subject<object>>();
  private readonly sentMessagesCompleters = new Map<
    string,
    Completer<Result<DomainFailure, object>>
  >();

  public startSocket(): void {
    if (
      this._connectionState.value == SocketConnectionState.connected ||
      this._connectionState.value == SocketConnectionState.connecting
    ) {
      console.error(
        `[socket] Attempted to start socket in ${this.connectionState.valueOf} state!`
      );
      return;
    }

    if (this._connectionState.value == SocketConnectionState.disconnected) {
      this._connectionState.next(SocketConnectionState.connecting);
    }

    this._connection?.close();
    this._connection = new WebSocket(
      `${
        EnvironmentVariables.socketBaseUrl
      }/chat?token=${this._authRepository.getAccessToken()}`
    );
    this._connection.onopen = (event) => {
      this._connectionState.next(SocketConnectionState.connected);
      this.reconnectionPolicy.reset();
      console.log("[socket] Connected", event);
    };
    this._connection.onclose = (event) => this.onClose(event);
    this._connection.onerror = (event) => {
      console.error("[socket]", event);
    };
    this._connection.onmessage = (event) => this.onMessage(event);
  }

  public close() {
    if (
      this._connection &&
      this._connectionState.value !== SocketConnectionState.disconnected
    ) {
      this._connection!.close(1000, SocketCloseReason.closedManually);
      this._connection = undefined;
      this._connectionState.next(SocketConnectionState.disconnected);
    }
  }

  private reconnect() {
    this._connectionState.next(SocketConnectionState.reconnecting);
    const nextRetryDelay = this.reconnectionPolicy.getNextRetryDelayInMs();
    console.log(
      `[socket] Attempt ${this.reconnectionPolicy.retryCount} to reconnect in ${nextRetryDelay} ms...`
    );
    clearTimeout(this.nextReconnectionTimer);
    this.nextReconnectionTimer = setTimeout(() => {
      this.startSocket();
    }, nextRetryDelay);
  }

  public reconnectManually() {
    if (
      this.connectionState !== SocketConnectionState.reconnecting ||
      !this.reconnectionPolicy.canRetryManually
    ) {
      return;
    }

    clearTimeout(this.nextReconnectionTimer);
    this.reconnectionPolicy.reset();
    this.startSocket();
  }

  private async onClose(event: CloseEvent) {
    console.log("[socket] Closed", event);

    if (
      event.reason === SocketCloseReason.closedManually ||
      event.reason === SocketCloseReason.deadSession ||
      event.reason === SocketCloseReason.maxConnectionReached
    ) {
      // Do not retry and don't allow retry
    } else if (event.reason === SocketCloseReason.invalidToken) {
      const result = await this._authRepository.renewToken();
      result.fold({
        onFailure: (failure) => {
          failure.fold({
            onNetworkFailure: () => {
              // TODO: What to do?
            },
            onServerFailure: () => {
              this._authRepository.removeCredentials();
              window.location.href = "/auth/phone";
              setTimeout(() => useStore.getState().resetState(), 500);
            },
          });
        },
        onSuccess: () => {
          this.reconnect();
        },
      });
    } else {
      this.reconnect();
    }
  }

  private onMessage(event: MessageEvent<object>) {
    const { id, action, payload } = JSON.parse(event.data as unknown as string);

    console.log("[socket] Received", {
      id,
      action,
      payload,
    });

    if (this.listeners.has(action)) {
      this.listeners.get(action)!.next(handleDates(payload) as object);
    } else {
      if (action !== "ack") {
        // TODO: Is it alright?
        console.warn(`[socket] No handler found for "${action}"!`, payload);
      }
    }

    if (this.sentMessagesCompleters.has(id)) {
      const completer = this.sentMessagesCompleters.get(id);
      this.sentMessagesCompleters.delete(id);
      completer?.resolve(Result.success(handleDates(payload) as object));
    }
  }

  public listen(action: string): Subject<object> {
    if (this.listeners.has(action)) {
      return this.listeners.get(action)!;
    }

    const subject = new Subject<object>();
    this.listeners.set(action, subject);
    return subject;
  }

  public async send(
    params: { action: string; payload: object },
    waitForResponse = true
  ): Promise<Result<DomainFailure, object>> {
    if (this._connectionState.value !== SocketConnectionState.connected) {
      return Result.failure(new NetworkFailure());
    }

    const { action, payload } = params;
    const id = uuidv4();

    const data = {
      id,
      action,
      payload,
    };

    this._connection!.send(JSON.stringify(data));

    console.log("[socket] Sent", data);

    if (!waitForResponse) {
      return Promise.resolve(Result.success({}));
    }

    const completer = new Completer<Result<DomainFailure, object>>();
    this.sentMessagesCompleters.set(id, completer);
    return completer.promise;
  }
}

class Completer<T> {
  readonly promise: Promise<T>;
  private _resolve!: (value: T | PromiseLike<T>) => void;
  private _reject!: (reason?: unknown) => void;

  public get resolve() {
    return this._resolve;
  }

  public get reject() {
    return this._reject;
  }

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
}
